import styles from './style.module.scss'
import client1 from './assets/client1.jpg'
import client2 from './assets/client2.jpg'
import client3 from './assets/client3.jpg'
import example1 from './assets/example1.jpg'
import example2 from './assets/example2.jpg'
import example3 from './assets/example3.jpg'
import promoVideo from './assets/okstavby.mp4'

import {Link} from "react-router-dom";
import {useEffect} from "react";

export const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id={'home'}>
            <div className={styles.first_content}>
                <div className={styles.first_line}>
                    Vítejte
                </div>
                <div className={styles.second_line}>
                    na stránkách našeho vyšívacího studia
                </div>
                <div className={styles.other_text}>
                    Vaše nápady a přání realizujeme již od roku 2003
                </div>

                <Link to={'/contact'} className={styles.link_button}>
                    <input className={styles.button} type="submit" name="send" value="Kontaktujte nás"/>
                </Link>
            </div>

            {/*<div id={styles.video_part_phone}>*/}
            {/*    <iframe src="https://www.youtube.com/embed/6Ev1ZjdxO_Y" width="720" height="405"*/}
            {/*            title={"promo video"} allowFullScreen*/}
            {/*            allow="encrypted-media; gyroscope;">*/}
            {/*    </iframe>*/}
            {/*</div>*/}

            <div id={styles.video_part_phone}>
                <video width="100%" controls preload="metadata">
                    <source src={promoVideo} type="video/mp4"/>
                    Váš prohlížeč nepodporuje přehrávání videí.
                </video>
            </div>

            <hr className={"section_separator"}/>

            <h3 className={styles.section_header}>
                NAŠI SPOKOJENÍ ZÁKAZNÍCI
            </h3>
            <div className={styles.home_pictures}>
                <a href={client1} title="Spokojený pes" data-lightbox="our-clients" className={"example-image-link"}>
                    <img src={client1} alt={"Spokojený pes"}/>
                </a>
                <a href={client2} title="Spokojené děti" data-lightbox="our-clients" className={"example-image-link"}>
                    <img src={client2} alt={"Spokojené děti"}/>
                </a>
                <a href={client3} title="Spokojená rodina" data-lightbox="our-clients" className={"example-image-link"}>
                    <img src={client3} alt={"Spokojená rodina"}/>
                </a>
            </div>

            <hr className={"section_separator"}/>

            <div className={styles.content}>
                <h2 className={styles.header1}>O NÁS</h2>
                <div className={styles.info}>
                    Naše firma se zabývá vyšíváním a šitím od roku 2003.
                    Spolupracujeme se&nbsp;zákazníky a vytváříme pro ně originální dárky, které přinášejí radost.
                    Kvalitu výšivek zaručují špičkové počítačem řízené stroje značky Tajima a&nbsp;individuální
                    přístup ke každé zakázce.
                </div>
            </div>

            <div className={styles.info_section}>
                <div className={styles.about_us_part}>
                    <div className={styles.grey_part}>
                        <h3 className={styles.header3}>
                            Co od nás můžete očekávat?
                        </h3>
                        <p>
                            Pomáháme zákazníkům plnit představy o&nbsp;jedinečných dárcích.
                            Vyšijeme vzory podle návrhů a&nbsp;předloh již&nbsp;od 1&nbsp;kusu.
                        </p>
                    </div>
                    <Link to={'/services'} className={styles.link_button}>
                        <input className={styles.small_button} type="submit" name="send" value="Ukázka služeb"/>
                    </Link>
                </div>

                {/*<div id={styles.video_part_pc}>*/}
                {/*    <iframe src="https://www.youtube.com/embed/6Ev1ZjdxO_Y" width="720" height="405"*/}
                {/*            title={"promo video"} allowFullScreen*/}
                {/*            allow="encrypted-media; gyroscope;">*/}
                {/*    </iframe>*/}
                {/*</div>*/}

                <div id={styles.video_part_pc}>
                    <video width="720" height="405" controls preload="metadata">
                        <source src={promoVideo} type="video/mp4"/>
                        Váš prohlížeč nepodporuje přehrávání videí.
                    </video>
                </div>


            </div>

            <hr className={"section_separator"}/>
            <h3 className={styles.section_header}>
                UKÁZKY NAŠÍ PRÁCE
            </h3>
            <div className={styles.home_pictures}>
                <a href={example1} title="Ručníky a osušky" data-lightbox="our-work" className={"example-image-link"}>
                    <img src={example1} alt={"Ručníky a osušky"}/>
                </a>
                <a href={example2} title="Vyšité tašky" data-lightbox="our-work" className={"example-image-link"}>
                    <img src={example2} alt={"Vyšité tašky"}/>
                </a>
                <a href={example3} title="Plyšoví pyžamožrouti" data-lightbox="our-work" className={"example-image-link"}>
                    <img src={example3} alt={"Plyšoví pyžamožrouti"}/>
                </a>
            </div>
            <div className={styles.home_section_button}>
                <Link to={'/galery'} className={styles.link_button}>
                    <input className={styles.button} type="submit" name="send" value="Chci vidět víc"/>
                </Link>
            </div>


        </div>

    )
};