import styles from './style.module.scss'
import logo from '../../assets/logo.webp'
import burgerMenu from './../../assets/black-burger.png'
import {useState} from 'react'
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

export const Navigation = () => {

    const [showMenu, setShowMenu] = useState(false);

    let menu;
    if (showMenu) {
        menu = <nav className={styles.mobile_navigation_links}>
            <Link to={'/services'}>
                <div onClick={() => setShowMenu(!showMenu)}
                >Služby
                </div>
            </Link>

            <Link to={'/contact'}>
                <div onClick={() => setShowMenu(!showMenu)}
                >Kontakt
                </div>
            </Link>

            <Link to={'/galery'}>
                <div onClick={() => setShowMenu(!showMenu)}
                >Galerie
                </div>
            </Link>
            <a href={"/"}>
                <div onClick={() => setShowMenu(!showMenu)}
                >Domů
                </div>
            </a>

        </nav>
    } else {
        menu = <div>
        </div>
    }

    return (
        <div className={styles.background_color}>
            <header id={styles.header}>
                <nav id={styles.nav}>
                    <div className={styles.left_part}>
                        <ul className={styles.nav_list}>
                            <li>
                                <Link to={'/services'}
                                      className={styles.nav_link}
                                >Služby</Link>
                            </li>
                            <li>
                                <Link to={'/contact'}
                                      className={styles.nav_link}
                                >Kontakt</Link>
                            </li>
                        </ul>
                    </div>
                    <HashLink to={'/#home'} className={styles.logo_link}>
                        <img src={logo} alt={'logo společnosti Dárek jako vyšitý'} className={styles.nav_logo}/>
                    </HashLink>
                    <div className={styles.right_part}>
                        <ul className={styles.nav_list}>
                            <li>
                                <Link to={'/galery'}
                                      className={styles.nav_link}
                                >Galerie</Link>
                            </li>
                            <li>
                                <Link to={'/'}
                                      className={styles.nav_link}
                                >O nás</Link>
                            </li>
                        </ul>
                    </div>
                    <div id={styles.burgerMenu}>
                        <img src={burgerMenu} alt={'hamburger menu'}
                             onClick={() => setShowMenu(!showMenu)}
                        />
                    </div>
                </nav>

            </header>
            {menu}
        </div>
    )
}