import styles from './style.module.scss'
import ig from "./assets/ig.png";
import fb from "./assets/fb.png";
import complaint from './assets/REKLAMACNI_PROTOKOL.pdf';
import terms from "./assets/OBCHODNI_PODMINKY_NEW.pdf";

export const Footer = () => {

    const onComplaintClick = () => {
        fetch(complaint).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = "Reklamace";
                alink.click();
            })
        })
    }

    const onTermsClick = () => {
        fetch(terms).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = "Obchodní podmínky";
                alink.click();
            })
        })
    }

    return (
        <footer >
            <div className={styles.footer}>
            <div className={styles.contracts}>
                <a className={styles.download_link}
                    onClick={onTermsClick}
                    download>Obchodní podmínky
                </a>
                <a className={styles.download_link}
                   onClick={onComplaintClick}
                   download>Reklamační formulář
                </a>
                <p>IČO: 26763982</p>
                <p>DIČ: CZ26763982</p>
            </div>

            <div className={styles.social_network}>
                <div>Sledujte nás na sociálních sítích</div>
                <div>
                    <a href={"https://www.instagram.com/darek_jako_vysity/?hl=cs"}>
                        <img src={ig} alt={"instagram logo with link"}/>
                    </a>
                    <a href={"https://www.facebook.com/darekjakovysity"}>
                        <img src={fb} alt={"facebook logo with link"}/>
                    </a>
                </div>
            </div>
            </div>
            <div className={styles.black_part}>
                © Copyright 2023&nbsp; <a href={"https://digitalnoodles.cz/"}>digitalnoodles.cz</a>
            </div>
        </footer>
    )
};