import styles from './style.module.scss'
import img from './assets/malovane_mini.webp'
import img2 from './assets/postrani_mini.webp'
import img3 from './assets/obr.2-1_mini.webp'
import img4 from './assets/obr. 4_mini.webp'
import img5 from './assets/obr. 3_mini.webp'
import img6 from './assets/obr.5_mini.webp'
import {useEffect} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";

export const Services = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className={styles.mainContainer}>
                <h1 className={styles.mainTopic}>Služby</h1>
                <div className={styles.flexRow}>
                    <div className={styles.leftC}>
                        <div className={styles.contentWrapper}>
                            <div className={styles.content}>
                                <h2>Co umíme?</h2>
                                <ul>
                                    <li className={styles.strong}><strong>Vyšijeme Vám dle přání:</strong></li>
                                    <li>firemní loga, monogramy, libovolé texty, obrázky, zjednodušené fotografie</li>
                                    <li>na polštářky, oděvy, kšiltovky, bytové doplňky, tašky nebo na&nbsp;jakýkoliv
                                        materiál,
                                        který jehla zvládne prošít
                                    </li>
                                </ul>
                            </div>
                            <div className={[styles.imageWrapper].join(" ")}>
                                <LazyLoadImage className={styles.imgMobile} src={img2} alt={'img'} width={0} loading={"lazy"} height={0}/>
                            </div>
                        </div>
                        <div className={[styles.imageWrapper].join(" ")}>
                            <LazyLoadImage className={styles.imgPC} src={img3} alt={'img'} width={0} loading={"lazy"} height={0}/>
                        </div>
                        <div className={styles.contentWrapper}>
                            <div className={styles.content}>
                                <ul>
                                    <li className={styles.strong}><strong>Na zakázku ušijeme:</strong></li>
                                    <li>polštářky, kapsáře, ubrusy, jednoduché kabelky a oděvy</li>
                                </ul>
                            </div>
                            <div className={[styles.imageWrapper].join(" ")}>
                                <LazyLoadImage className={styles.imgMobile} src={img5} alt={'img'} width={0} loading={"lazy"} height={0}/>
                            </div>
                        </div>
                        <div className={[styles.imageWrapper].join(" ")}>
                            <LazyLoadImage className={styles.imgPC} src={img4} alt={'img'} width={0} loading={"lazy"} height={0}/>
                        </div>
                        <div className={styles.contentWrapper}>
                            <div className={styles.content}>
                                <ul>
                                    <li className={styles.strong}><strong>Poradíme:</strong></li>
                                    <li>se vším ohledně výšivek, aplikací, puffování, šití</li>
                                    <li>s výběrem dárků</li>
                                </ul>
                            </div>
                        </div>
                        <div className={[styles.imageWrapper].join(" ")}>
                            <LazyLoadImage className={styles.imgMobile} src={img6} alt={'img'} width={0} loading={"lazy"} height={0}/>
                        </div>
                    </div>

                    <div className={styles.rightC}>
                        <div className={[styles.imageWrapper].join(" ")}>
                            <LazyLoadImage className={styles.imgPC} src={img2} alt={'img'} width={0} loading={"lazy"} height={0}/>
                        </div>
                        <div className={styles.contentWrapper}>
                            <div className={styles.content}>
                                <ul>
                                    <li className={styles.strong}><strong>Dokážeme:</strong></li>
                                    <li>puffovat, filtrovat, aplikovat</li>
                                </ul>
                            </div>
                            <div className={[styles.imageWrapper].join(" ")}>
                                <LazyLoadImage className={styles.imgMobile} src={img3} alt={'img'} width={0} loading={"lazy"} height={0}/>
                            </div>
                        </div>
                        <div className={[styles.imageWrapper].join(" ")}>
                            <LazyLoadImage className={styles.imgPC} src={img5} alt={'img'} width={0} loading={"lazy"} height={0}/>
                        </div>
                        <div className={styles.contentWrapper}>
                            <div className={styles.content}>
                                <ul>
                                    <li className={styles.strong}><strong>Upcyklujeme:</strong></li>
                                    <li>máme pouze jednu planetu a z&nbsp;toho důvodu využíváme i&nbsp;zbytkové oděvy a
                                        materiály k vytvoření nových výrobků
                                    </li>
                                </ul>
                            </div>
                            <div className={[styles.imageWrapper].join(" ")}>
                                <LazyLoadImage className={styles.imgMobile} src={img4} alt={'img'} width={0} loading={"lazy"} height={0}/>
                            </div>
                        </div>
                        <div className={[styles.imageWrapper].join(" ")}>
                            <LazyLoadImage className={styles.imgPC} src={img6} alt={'img'} width={0} loading={"lazy"} height={0}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}