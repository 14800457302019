import '../App.css';
import {Home} from "./homePage/Home";
import {Contact} from "./contactPage/Contact";
import {Route, Routes} from "react-router-dom";
import {Gallery} from "./galleryPage/Gallery";
import {Services} from "./servicePage/Services";
import {Navigation} from "../parts/navigation/Navigation";
import {Footer} from "../parts/footer/Footer";
import DocumentMeta from 'react-document-meta';
import {Helmet} from "react-helmet";

const TITLE = 'Dárek jako vyšitý'
const description = 'Dárek jako vyšitý se zabývá výrobou originálních dárků dle přání zákazníků. ' +
    'Ke každému klientovi a jeho vysněnému produktu přistupujeme individuálně. ' +
    'Vyšijeme vzory podle návrhů a předloh již od 1 kusu.'
const charSet = "utf-8"
const lang = 'cs'
const keywords = 'Výšivky, vyšít, vyšívání, dárek, originalita, handmade, ' +
    'ruční výroba, šití, zakázková výroba, originální dárek, ruční práce' +
    'na zakázku, výšivka, dárek jako vyšitý, vyšívání od jednoho kusu, vyšívání od 1 kusu,' +
    'vyšívání Praha, vyšívání Středočeský kraj, vyšívání Morava, originální výšivka' +
    'vyšívání pro firmy, vyšívání pro jednotlivce, vyšívání pro školy a školky';

function App() {

    const meta = {
        title: TITLE,
        description: description,
        canonical: 'http://example.com/path/to/page',
        meta: {
            charset: charSet,
            lang: lang,
            name: {
                keywords: keywords
            }
        }
    }

    return (
        <div className="App">
            <Helmet>
                <meta charSet={charSet} />
                <meta name="keywords" content={keywords} />
                <html lang={lang} />
                <meta name="description" content={description} />
                <title>{TITLE}</title>
            </Helmet>
            <DocumentMeta {...meta} />
            <Navigation/>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/contact" element={<Contact/>}/>
                <Route exact path="/galery" element={<Gallery/>}/>
                <Route exact path="/services" element={<Services/>}/>
            </Routes>
            <hr className={"section_separator"}/>
            <Footer/>
        </div>
    );
}

export default App;
