import styles from "./style.module.scss";
import {LazyLoadImage} from 'react-lazy-load-image-component';


import pic1 from "./assets/DEF02539.webp"
import pic2 from "./assets/DEF02541.webp"
import pic3 from "./assets/DEF02542.webp"
import pic4 from "./assets/DEF02545.webp"
import pic5 from "./assets/DEF02546.webp"
import pic7 from "./assets/DEF02547.webp"
import pic8 from "./assets/DEF02551.webp"
import pic9 from "./assets/DEF02555.webp"
import pic10 from "./assets/DEF02556.webp"
import pic11 from "./assets/DEF02558.webp"
import pic12 from "./assets/DEF02560.webp"
import pic13 from "./assets/DEF02562.webp"
import pic14 from "./assets/DEF02564.webp"
import pic15 from "./assets/DEF02565.webp"
import pic16 from "./assets/DEF02568.webp"
import pic17 from "./assets/DEF02569.webp"
import pic18 from "./assets/DEF02570.webp"
import pic19 from "./assets/DEF02571.webp"
import pic20 from "./assets/DEF02574.webp"
import pic21 from "./assets/DEF02575.webp"
import pic22 from "./assets/DEF02577.webp"
import pic23 from "./assets/DEF02578.webp"
import pic24 from "./assets/DEF02580.webp"
import pic25 from "./assets/DEF02588.webp"
import pic26 from "./assets/DEF02590.webp"
import pic27 from "./assets/DEF02592.webp"
import pic28 from "./assets/DEF02597.webp"
import pic29 from "./assets/DEF02600.webp"
import pic30 from "./assets/DEF02601.webp"
import pic31 from "./assets/DEF02602.webp"
import pic32 from "./assets/DEF02603.webp"
import pic33 from "./assets/DEF02604.webp"
import pic34 from "./assets/DEF02605.webp"
import pic35 from "./assets/DEF02606.webp"
import pic36 from "./assets/DEF02614.webp"
import pic37 from "./assets/DEF02617.webp"
import pic38 from "./assets/DEF02619.webp"
import pic39 from "./assets/DEF02620.webp"
import pic40 from "./assets/DEF02621.webp"
import pic41 from "./assets/DEF02622.webp"
import pic42 from "./assets/DEF02624.webp"
import pic43 from "./assets/DEF02626.webp"
import pic44 from "./assets/DEF02631.webp"
import pic45 from "./assets/DEF02632.webp"
import pic46 from "./assets/DEF02633.webp"
import pic47 from "./assets/DEF02635.webp"
import pic48 from "./assets/DEF02636.webp"
import pic49 from "./assets/DEF02640.webp"
import pic50 from "./assets/DEF02641.webp"
import pic51 from "./assets/DEF02643.webp"
import pic52 from "./assets/DEF02644.webp"
import pic53 from "./assets/DEF02645.webp"
import pic54 from "./assets/DEF02646.webp"
import pic55 from "./assets/DEF02647.webp"
import pic56 from "./assets/DEF02648.webp"
import pic57 from "./assets/DEF02649.webp"
import pic58 from "./assets/DEF02652.webp"
import pic59 from "./assets/DEF02654.webp"
import pic60 from "./assets/DEF02655.webp"
import pic61 from "./assets/DEF02656.webp"
import pic62 from "./assets/DEF02657.webp"
import pic63 from "./assets/DEF02659.webp"
import pic64 from "./assets/DEF02660.webp"
import pic65 from "./assets/DEF02662.webp"
import pic67 from "./assets/DEF02665.webp"
import pic68 from "./assets/DEF02666.webp"
import pic69 from "./assets/DEF02667.webp"
import pic70 from "./assets/DEF02668.webp"
import pic71 from "./assets/DEF02670.webp"
import pic72 from "./assets/DEF02671.webp"

import pic1_mini from "./assets/DEF02539_mini.webp"
import pic2_mini from "./assets/DEF02541_mini.webp"
import pic3_mini from "./assets/DEF02542_mini.webp"
import pic4_mini from "./assets/DEF02545_mini.webp"
import pic5_mini from "./assets/DEF02546_mini.webp"
import pic7_mini from "./assets/DEF02547_mini.webp"
import pic8_mini from "./assets/DEF02551_mini.webp"
import pic9_mini from "./assets/DEF02555_mini.webp"
import pic10_mini from "./assets/DEF02556_mini.webp"
import pic11_mini from "./assets/DEF02558_mini.webp"
import pic12_mini from "./assets/DEF02560_mini.webp"
import pic13_mini from "./assets/DEF02562_mini.webp"
import pic14_mini from "./assets/DEF02564_mini.webp"
import pic15_mini from "./assets/DEF02565_mini.webp"
import pic16_mini from "./assets/DEF02568_mini.webp"
import pic17_mini from "./assets/DEF02569_mini.webp"
import pic18_mini from "./assets/DEF02570_mini.webp"
import pic19_mini from "./assets/DEF02571_mini.webp"
import pic20_mini from "./assets/DEF02574_mini.webp"
import pic21_mini from "./assets/DEF02575_mini.webp"
import pic22_mini from "./assets/DEF02577_mini.webp"
import pic23_mini from "./assets/DEF02578_mini.webp"
import pic24_mini from "./assets/DEF02580_mini.webp"
import pic25_mini from "./assets/DEF02588_mini.webp"
import pic26_mini from "./assets/DEF02590_mini.webp"
import pic27_mini from "./assets/DEF02592_mini.webp"
import pic28_mini from "./assets/DEF02597_mini.webp"
import pic29_mini from "./assets/DEF02600_mini.webp"
import pic30_mini from "./assets/DEF02601_mini.webp"
import pic31_mini from "./assets/DEF02602_mini.webp"
import pic32_mini from "./assets/DEF02603_mini.webp"
import pic33_mini from "./assets/DEF02604_mini.webp"
import pic34_mini from "./assets/DEF02605_mini.webp"
import pic35_mini from "./assets/DEF02606_mini.webp"
import pic36_mini from "./assets/DEF02614_mini.webp"
import pic37_mini from "./assets/DEF02617_mini.webp"
import pic38_mini from "./assets/DEF02619_mini.webp"
import pic39_mini from "./assets/DEF02620_mini.webp"
import pic40_mini from "./assets/DEF02621_mini.webp"
import pic41_mini from "./assets/DEF02622_mini.webp"
import pic42_mini from "./assets/DEF02624_mini.webp"
import pic43_mini from "./assets/DEF02626_mini.webp"
import pic44_mini from "./assets/DEF02631_mini.webp"
import pic45_mini from "./assets/DEF02632_mini.webp"
import pic46_mini from "./assets/DEF02633_mini.webp"
import pic47_mini from "./assets/DEF02635_mini.webp"
import pic48_mini from "./assets/DEF02636_mini.webp"
import pic49_mini from "./assets/DEF02640_mini.webp"
import pic50_mini from "./assets/DEF02641_mini.webp"
import pic51_mini from "./assets/DEF02643_mini.webp"
import pic52_mini from "./assets/DEF02644_mini.webp"
import pic53_mini from "./assets/DEF02645_mini.webp"
import pic54_mini from "./assets/DEF02646_mini.webp"
import pic55_mini from "./assets/DEF02647_mini.webp"
import pic56_mini from "./assets/DEF02648_mini.webp"
import pic57_mini from "./assets/DEF02649_mini.webp"
import pic58_mini from "./assets/DEF02652_mini.webp"
import pic59_mini from "./assets/DEF02654_mini.webp"
import pic60_mini from "./assets/DEF02655_mini.webp"
import pic61_mini from "./assets/DEF02656_mini.webp"
import pic62_mini from "./assets/DEF02657_mini.webp"
import pic63_mini from "./assets/DEF02659_mini.webp"
import pic64_mini from "./assets/DEF02660_mini.webp"
import pic65_mini from "./assets/DEF02662_mini.webp"
import pic67_mini from "./assets/DEF02665_mini.webp"
import pic68_mini from "./assets/DEF02666_mini.webp"
import pic69_mini from "./assets/DEF02667_mini.webp"
import pic70_mini from "./assets/DEF02668_mini.webp"
import pic71_mini from "./assets/DEF02670_mini.webp"
import pic72_mini from "./assets/DEF02671_mini.webp"

import "../../../node_modules/lightbox2/dist/js/lightbox-plus-jquery.min.js"
import "./lightbox-edit.css"
import {useEffect} from "react";

export const Gallery = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<div>

            <h1 className={styles.topic}>Galerie</h1>
            <div className={styles.content}>
                <main>
                    <div id={styles.reference}>
                        <a href={pic12} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic12_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}
                            />
                        </a>
                        <a href={pic13} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic13_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}
                            />
                        </a>
                        <a href={pic14} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic14_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic15} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic15_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic28} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic28_mini} className={styles.galery_img}
                                           alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic16} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic16_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic17} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic17_mini} className={styles.galery_img} threshold={100}
                                           alt={"Obrázek"} loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic18} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic18_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic19} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic19_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic20} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic20_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic21} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic21_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic22} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic22_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic23} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic23_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic24} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic24_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic25} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic25_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic26} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic26_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic27} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic27_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic36} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic36_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic1} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic1_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic2} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic2_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic3} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic3_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic4} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic4_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic5} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic5_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic7} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic7_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic8} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic8_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic9} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic9_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic10} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic10_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic11} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic11_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic29} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic29_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic30} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic30_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic31} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic31_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic32} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic32_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic33} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic33_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic38} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic38_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic34} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic34_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic35} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic35_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic37} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic37_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic39} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic39_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic40} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic40_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic41} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic41_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic42} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic42_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic43} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic43_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic44} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic44_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic45} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic45_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic46} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic46_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic47} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic47_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic48} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic48_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic49} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic49_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic50} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic50_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic51} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic51_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic52} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic52_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic53} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic53_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic55} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic55_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic56} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic56_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic54} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic54_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic57} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic57_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic62} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic62_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic63} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic63_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic58} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic58_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic59} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic59_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic60} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic60_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic61} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic61_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>

                        <a href={pic64} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic64_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>

                        <a href={pic67} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic67_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic68} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic68_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic69} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic69_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic70} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic70_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic71} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic71_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic72} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic72_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                        <a href={pic65} title="Obrázek" data-lightbox="example-set">
                            <LazyLoadImage src={pic65_mini} className={styles.galery_img} alt={"Obrázek"}
                                           loading={"lazy"} width={0} height={0}/>
                        </a>
                    </div>
                </main>
            </div>
        </div>)
};